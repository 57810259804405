import React from "react";
import {
  Navbar,
  Avatar,
  Text,
  Group,
  createStyles,
  UnstyledButton,
  getStylesRef,
  Box,
  NavLink,
} from "@mantine/core";
import profileImg from "../../images/profile.jpg";
import { Link, useLocation } from "react-router-dom";
import navData from "./navData";
import { useAppSelector } from "../../app/hooks";
import {
  IconTimelineEventText,
  IconApiApp,
  IconBrandProducthunt,
  IconChecklist,
  IconReportAnalytics,
} from "@tabler/icons-react";
import { IconHomeMove } from "@tabler/icons-react";

interface Props {
  burgerOpen: boolean;
}
const Nav = (props: Props) => {
  const location = useLocation();
  const { name } = useAppSelector((state) => state.userData);
  const { classes, cx } = useStyles();

  const links = navData.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.link === location.pathname,
      })}
      to={item.link}
      key={item.label}
    >
      {item.link === location.pathname ? (
        <>
          <Box className={classes.activeIcon}></Box>
          <item.icon className={classes.linkIconActive} stroke={1.5} />
          <span>{item.label}</span>
        </>
      ) : (
        <>
          <item.icon className={classes.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </>
      )}
    </Link>
  ));
  return (
    <Navbar
      mah={1000}
      maw=" 810px"
      width={{ 75: props.burgerOpen ? 75 : 300 }}
      className={classes.scrollbarHidden}
      sx={{
        overflow: "auto",
        transition: "width 300ms ease, min-width 300ms ease",
      }}
      style={{
        boxShadow: "5px 10px 15px 2px  #e4e5e6",
      }}
    >
      <Navbar.Section>
        {props.burgerOpen === false ? (
          <UnstyledButton className={classes.profileBox}>
            <Group>
              <Avatar
                src={profileImg}
                size={40}
                color="#ff008a"
                radius="md"
                className={classes.profilePicture}
              ></Avatar>

              <Box>
                <Text weight="bold">{name}</Text>
                <Text size="sm" weight="bold" color="dimmed">
                  Admin
                </Text>
              </Box>
            </Group>
          </UnstyledButton>
        ) : (
          <UnstyledButton className={classes.profileBoxCollapsed}>
            <Group>
              <Avatar
                src={profileImg}
                size={40}
                color="#ff008a"
                radius="md"
                className={classes.profilePicture}
              ></Avatar>
            </Group>
          </UnstyledButton>
        )}
      </Navbar.Section>

      <NavLink
        label="HR"
        icon={<IconHomeMove className={classes.navLinkIcon} stroke={1.5} />}
        childrenOffset={28}
        defaultOpened
        className={classes.navLinkText}
      >
        <Link
          className={cx(classes.link, {
            [classes.linkActive]: "/" === location.pathname,
          })}
          to={"/"}
        >
          {"/" === location.pathname && (
            <Box className={classes.activeIcon}></Box>
          )}
          <IconChecklist
            className={
              "/" === location.pathname
                ? classes.linkIconActive
                : classes.linkIcon
            }
            stroke={1.5}
          />
          <span>Today Attendacne</span>
        </Link>
      </NavLink>

      <Navbar.Section mah={1000} w={300}>
        {links}
      </Navbar.Section>

      <NavLink
        label="Report"
        icon={<IconReportAnalytics className={classes.navLinkIcon} />}
        childrenOffset={28}
        defaultOpened
        className={classes.navLinkText}
      >
        <Link
          className={cx(classes.link, {
            [classes.linkActive]: "/task-report" === location.pathname,
          })}
          to={"/task-report"}
        >
          {"/task-report" === location.pathname && (
            <Box className={classes.activeIcon}></Box>
          )}
          <IconChecklist
            className={
              "/task-report" === location.pathname
                ? classes.linkIconActive
                : classes.linkIcon
            }
            stroke={1.5}
          />
          <span>Task</span>
        </Link>
        <Link
          className={cx(classes.link, {
            [classes.linkActive]: "/timeline" === location.pathname,
          })}
          to={"/timeline"}
        >
          {"/timeline" === location.pathname && (
            <Box className={classes.activeIcon}></Box>
          )}
          <IconTimelineEventText
            className={
              "/timeline" === location.pathname
                ? classes.linkIconActive
                : classes.linkIcon
            }
            stroke={1.5}
          />
          <span>Timeline</span>
        </Link>
      </NavLink>

      <NavLink
        label="API'S"
        icon={<IconApiApp stroke={2} className={classes.navLinkIcon} />}
        childrenOffset={28}
        defaultOpened
        className={classes.navLinkText}
      >
        <Link
          className={cx(classes.link, {
            [classes.linkActive]: "/praoject-api" === location.pathname,
          })}
          to={"/project-api"}
        >
          {"/project-api" === location.pathname && (
            <Box className={classes.activeIcon}></Box>
          )}
          <IconBrandProducthunt
            className={
              "/task-report" === location.pathname
                ? classes.linkIconActive
                : classes.linkIcon
            }
            stroke={1.5}
          />
          <span>Project_api</span>
        </Link>
      </NavLink>
    </Navbar>
  );
};

const useStyles = createStyles((theme) => ({
  scrollbarHidden: {
    overflowY: "auto", // Scrolling enable
    scrollbarWidth: "none", // Firefox ke liye scrollbar hide
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari ke liye scrollbar hide
    },
  },

  profileBox: {
    padding: "0.5rem 0.5rem",
    borderRadius: `${theme.radius.lg} ${theme.radius.lg}`,
    margin: ` ${theme.spacing.md} ${theme.spacing.md}`,
    boxShadow: " 0 4px 4px rgba(0, 0, 0, 0.25);",
    width: "17rem",
  },
  profileBoxCollapsed: {
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: 0,
  },
  profilePicture: {
    border: "1px solid #F00F89",
  },
  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: "#5b5e65",
    marginBottom: ".2rem",
    height: 45,
    padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
    fontWeight: 700,

    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },
  activeIcon: {
    ref: getStylesRef("activeIcon"),
    width: "0.2rem",
    backgroundColor: "white",
    height: "2rem",
  },

  navLinkText: {
    fontSize: 14,
    fontWeight: 700,
    color: "#5b5e65",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      transition: " all 0.2s ease-in-out 0s;",

      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "#ff008a",
        backgroundColor: "#ff008a",
      },
    },
  },

  navLinkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a",
    marginRight: theme.spacing.xs,
    marginLeft: "1.2rem",
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: "#ff008a ",
    marginRight: theme.spacing.xl,
    marginLeft: "1.2rem ",
  },

  linkIconActive: {
    ref: getStylesRef("linkIconActive"),
    color: "#ff008a",
    marginRight: theme.spacing.xl,
    marginLeft: "1rem ",
  },
  linkActive: {
    "&, &:hover": {
      backgroundColor: "#ff008a",
      color: "white",
      [`& .${getStylesRef("icon")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("linkIconActive")}`]: {
        color: "white",
      },
      [`& .${getStylesRef("activeIcon")}`]: {
        color: "white",
        backgroundColor: "white",
      },
    },
  },
}));

export default Nav;
