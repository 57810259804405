import { Box, SimpleGrid } from "@mantine/core";
import React, { useState } from "react";
import TableHeader from "./table-header/TableHeader";
import GridView from "./views/grid-view/GridView";
import TableList from "./views/table-view/TableList";
import ThemePagination from "./pagination/ThemePagination";
import ThemeLoader from "../loader/ThemeLoader";
import AttendanceCard from "../attendance-card/AttendanceCard";
import NoData from "../no-data/NoData";

const CustomTable: React.FC<TCustomTable> = ({
  headerProps,
  columns,
  data,
  loading,
  paginationProps,
  isDashboardTable,
  grid,
  attendanceProps,
}) => {
  const [type, setType] = useState<TTableView>("list");

  return (
    <Box style={{ position: "relative", minHeight: "100vh" }}>
      <TableHeader {...headerProps} setType={setType} view={type} grid={grid} />
      <Box>{attendanceProps}</Box>
      <Box style={{ position: "relative", minHeight: "75vh" }}>
        {type === "grid" ? (
          <GridLayout data={data} isDashboardTable={isDashboardTable} />
        ) : (
          <TableList data={data} columns={columns} />
        )}
        <ThemeLoader loading={loading ?? false} />
      </Box>

      <Box>
        {paginationProps && (
          <ThemePagination
            setPage={paginationProps.setPage}
            totalPages={paginationProps.totalPages}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomTable;

interface Props {
  isDashboardTable: boolean;
  data: TCustomTable["data"];
}

const GridLayout: React.FC<Props> = ({ isDashboardTable, data }) => {
  if (isDashboardTable) {
    return data.length === 0 ? (
      <NoData title="No Data Found" />
    ) : (
      <SimpleGrid cols={3} spacing="xl" mt={25}>
        {data.map((attendance: TAttendanceData, i) => (
          <AttendanceCard key={"att-card" + i} data={attendance} />
        ))}
      </SimpleGrid>
    );
  }
  return (
    <SimpleGrid cols={3} spacing="xl" mt={25}>
      {data.map((student: TEmployeeData) => (
        <GridView data={student} key={student._id} />
      ))}
    </SimpleGrid>
  );
};
