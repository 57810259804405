import { format } from "date-fns";

export const getSlotBackgroundColor = (
  date: Date,
  isLeaveDay: boolean,
  isHoliday: (date: string) => boolean
): string => {
  const formattedDate = format(date, "yyyy-MM-dd");

  if (isHoliday(formattedDate)) {
    return "#d90303a8";
  }
  if (isLeaveDay) {
    return "#f89b29";
  }
  return "#f8f9fa";
};
