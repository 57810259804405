import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getProjectApi = async () => {
  const response: TServerResponse<TApiDocs[], unknown> = await request({
    url: apiUrls.projectApi.Add_PROJECT_API,
    method: "GET",
  });
  return response;
};

export const useGetProjectApi = () => {
  return useQuery(["api's", "add-project-showApi"], () => getProjectApi());
};
