import { parseISO } from "date-fns";

export const checkOverlap = (
  eventA: { start_date: string; end_date: string },
  eventB: { start_date: string; end_date: string }
) => {
  const startA = parseISO(eventA.start_date);
  const endA = parseISO(eventA.end_date);
  const startB = parseISO(eventB.start_date);
  const endB = parseISO(eventB.end_date);

  return (
    (startA < endB && endA > startB) || startA.getTime() === startB.getTime()
  );
};
