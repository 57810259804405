import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllLeaveDate = async (params: TQueryParams) => {
  const response: TServerResponse<ITaskReport[], unknown> = await request({
    url: apiUrls.reports.GET_ALL_LEAVE_DATE,
    method: "GET",
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.searchParams?.search,
      searchFieldObjectKey: ["studentId"],
    },
  });

  return response;
};

export const useGetAllLeaveDate = (params: TQueryParams) => {
  return useQuery(["admin", "all-task-leave-date", params], () =>
    getAllLeaveDate(params)
  );
};
