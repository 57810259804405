import moment from "moment";
import { useGetAllHoliday } from "../../../../hooks/holiday/query/getAllHoliday.query";

export const allHolidayHook = () => {
  const { data: allHoliday } = useGetAllHoliday();

  const isHoliday = (date: string) => {
    const holidays = allHoliday?.data ?? [];
    if (!Array.isArray(holidays)) {
      console.error("Expected an array, but got:", holidays);
      return false;
    }

    return holidays.some((holiday: { date: moment.MomentInput }) =>
      moment(holiday.date).isSame(date, "day")
    );
  };

  return isHoliday;
};
