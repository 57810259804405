import moment from "moment";

export const calculateHours = (
  startDate: Date | null,
  endDate: Date | null,
  dateType: string | null,
  holidays: { date: string }[] = [],
  leaves: { dates: string[]; type: string; status: string }[] = []
): number => {
  if (!startDate || !endDate) return 0;

  const localStartDate = moment(startDate).local().startOf("day");
  const localEndDate = moment(endDate).local().endOf("day");

  const holidaysInRange = holidays.filter((holiday) =>
    moment(holiday.date)
      .local()
      .startOf("day")
      .isBetween(localStartDate, localEndDate, undefined, "[]")
  );

  let totalHours = 0;

  if (dateType === "Day") {
    totalHours = 8;
  } else if (dateType === "Week") {
    totalHours = 8 * 7;
  } else if (dateType === "Month") {
    const daysInMonth = localStartDate.daysInMonth();
    totalHours = 8 * daysInMonth;
  } else if (dateType === "Custom") {
    const daysDifference = localEndDate.diff(localStartDate, "days") + 1;
    totalHours = 8 * daysDifference;
  }

  // Subtract 8 hours for each holiday in the range
  totalHours -= holidaysInRange.length * 8;

  // Calculate hours to subtract for leaves
  const leaveHours = leaves.reduce((acc, leave) => {
    const leaveDates = leave.dates.filter((date) =>
      moment(date)
        .local()
        .startOf("day")
        .isBetween(localStartDate, localEndDate, undefined, "[]")
    );
    if (leave.status === "accepted") {
      let hoursToSubtract = 0;
      if (leave.type === "fullday") {
        hoursToSubtract = 8;
      } else if (leave.type === "halfday") {
        hoursToSubtract = 4;
      }
      acc += leaveDates.length * hoursToSubtract;
    }

    return acc;
  }, 0);

  totalHours -= leaveHours;

  return totalHours;
};
