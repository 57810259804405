import React from "react";
import { format, parseISO } from "date-fns";
import moment from "moment";

interface IWorkLogDurationCalculatorProps {
  workLogs: { start_date: string; end_date: string }[];
  date: Date;
}

const WorkLogDurationCalculator: React.FC<IWorkLogDurationCalculatorProps> = ({
  workLogs,
  date,
}) => {
  let overallTotalHours = 0;
  let overallTotalMinutes = 0;

  const filteredWorkLogs = workLogs.filter(
    (workLog) =>
      format(parseISO(workLog.start_date), "yyyy-MM-dd") ===
      format(date, "yyyy-MM-dd")
  );

  filteredWorkLogs.sort(
    (a, b) =>
      parseISO(a.start_date).getTime() - parseISO(b.start_date).getTime()
  );

  const mergedWorkLogs: { start_date: string; end_date: string }[] = [];

  filteredWorkLogs.forEach((workLog) => {
    const startTime = parseISO(workLog.start_date);
    const endTime = parseISO(workLog.end_date);

    if (
      mergedWorkLogs.length === 0 ||
      parseISO(mergedWorkLogs[mergedWorkLogs.length - 1].end_date) < startTime
    ) {
      mergedWorkLogs.push(workLog);
    } else {
      const lastWorkLog = mergedWorkLogs[mergedWorkLogs.length - 1];
      const mergedEndTime = moment
        .max(moment(lastWorkLog.end_date), moment(endTime))
        .toISOString();
      lastWorkLog.end_date = mergedEndTime;
    }
  });

  mergedWorkLogs.forEach((workLog) => {
    const startTime = parseISO(workLog.start_date);
    const endTime = parseISO(workLog.end_date);
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));

    overallTotalHours += duration.hours();
    overallTotalMinutes += duration.minutes();
  });

  overallTotalHours += Math.floor(overallTotalMinutes / 60);
  overallTotalMinutes = overallTotalMinutes % 60;

  const overallFormattedDuration = `${overallTotalHours}h : ${overallTotalMinutes}m`;

  return <div style={{ fontWeight: 400 }}>({overallFormattedDuration})</div>;
};

export default WorkLogDurationCalculator;
