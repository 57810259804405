export type TEmpMappingValues = {
  _id?: string;
  employeeId: string;

  projects: { projectId: string; bandwidth: number }[];
};

export const AddEmpMappingValues: TEmpMappingValues = {
  _id: "",
  employeeId: "",
  projects: [],
};
