import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getAllTaskReport = async (params: TQueryParams) => {
  const response: TServerResponse<ITaskReport[], unknown> = await request({
    url: apiUrls.reports.GET_ALL_TASK,
    method: "GET",
    params: {
      ...params.paging,
      startDate: params.startDate,
      endDate: params.endDate,
      search: params.searchParams?.search,
      searchFieldObjectKey: ["studentID"],
    },
  });

  return response;
};

export const useGetAllTaskReport = (params: TQueryParams) => {
  return useQuery(["admin", "all-task", params], () =>
    getAllTaskReport(params)
  );
};
