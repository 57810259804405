import {
  Box,
  Text,
  createStyles,
  Indicator,
  Center,
  Grid,
  Flex,
} from "@mantine/core";
import React from "react";

interface IHeaderValues {
  extraData: {
    workingDays: number;
    leaveDays: number;
    holidays: number;
    absents: number;
  } | null;
}

const InfoCount: React.FC<IHeaderValues> = ({ extraData }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.mainBox} style={{ alignSelf: "end" }}>
      <Flex justify="space-between" align="start" wrap="wrap">
        <Grid className={classes.dayListContainer} align="center">
          <Grid.Col
            span={3}
            className={`${classes.dayList} ${classes.dayListStart}`}
          >
            <Center>
              <Indicator
                zIndex={0}
                size={8}
                position="middle-start"
                color="#009900"
              >
                <Text ml={10} size="xs">
                  Working Days
                  <Text ml={10} size="xs" span={true} c="dimmed">
                    {extraData?.workingDays ? extraData.workingDays : 0}
                  </Text>
                </Text>
              </Indicator>
            </Center>
          </Grid.Col>

          <Grid.Col
            span={3}
            className={`${classes.dayList} ${classes.dayListMid}`}
          >
            <Center>
              <Box>
                <Indicator
                  zIndex={0}
                  size={8}
                  position="middle-start"
                  color="#f79009"
                >
                  <Text ml={10} size="xs">
                    Leave Days
                    <Text ml={10} size="xs" span={true} c="dimmed">
                      {extraData?.leaveDays ? extraData.leaveDays : 0}
                    </Text>
                  </Text>
                </Indicator>
              </Box>
            </Center>
          </Grid.Col>

          <Grid.Col
            span={3}
            className={`${classes.dayList} ${classes.dayListMid}`}
          >
            <Center>
              <Box>
                <Indicator
                  zIndex={0}
                  size={8}
                  position="middle-start"
                  color="#ff4405"
                >
                  <Text ml={10} size="xs">
                    Absents
                    <Text ml={10} size="xs" span={true} c="dimmed">
                      {extraData?.absents ? extraData.absents : 0}
                    </Text>
                  </Text>
                </Indicator>
              </Box>
            </Center>
          </Grid.Col>

          <Grid.Col
            span={3}
            className={`${classes.dayList} ${classes.dayListEnd}`}
          >
            <Center>
              <Indicator
                zIndex={0}
                size={8}
                position="middle-start"
                color="#008080"
              >
                <Text ml={10} size="xs">
                  Holidays
                  <Text ml={10} size="xs" span={true} c="dimmed">
                    {extraData?.holidays ? extraData.holidays : 0}
                  </Text>
                </Text>
              </Indicator>
            </Center>
          </Grid.Col>
        </Grid>
      </Flex>
    </Box>
  );
};
const useStyles = createStyles(() => ({
  mainBox: {
    marginTop: 0,
  },
  currentDate: {
    marginBlock: "0.5rem",
  },
  dayListContainer: {
    alignItems: "center",
    marginBlock: "1rem",
    minWidth: "35rem",
  },
  dayList: {
    borderColor: "#9ea7b7",
    borderStyle: "solid",
    borderWidth: "1px",
  },
  dayListStart: {
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  dayListMid: {
    borderRight: "1px solid #9ea7b7", // Add right border here
  },
  dayListEnd: {
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
  calendarResetButton: {
    backgroundColor: "white",
    borderColor: "#ff008a",
    color: "#ff008a",
    "&:hover": {
      backgroundColor: "#ff008a",
      color: "white",
    },
  },
}));

export default InfoCount;
