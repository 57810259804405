import { array, number, object, string } from "yup";

export const AddEmpMappingValidationSchema = object({
  projectIds: array()
    .of(string().required("Project ID must be a string"))
    .required("Project IDs can't be empty"),
  employeeId: string().required("Employee ID can't be empty"),
  bandwidth: number()
    .required("Bandwidth can't be empty")
    .positive("Bandwidth must be a positive number"),
});
