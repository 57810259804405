import { Box } from "@mantine/core";
import React from "react";
import Scheduler from "./task-report-scheduler/Scheduler";

const Timeline = () => {
  return (
    <Box
      mt={12}
      py="md"
      pr={"sm"}
      pl={"sm"}
      bg={"#fff"}
      style={{ borderRadius: "calc(.5rem * 1)" }}
    >
      <Scheduler />
    </Box>
  );
};

export default Timeline;
