export const handleDateTypeChange = (
  value: string | null,
  setDateType: (value: React.SetStateAction<string | null>) => void,
  setStartDate: (value: React.SetStateAction<Date | null>) => void,
  setEndDate: (value: React.SetStateAction<Date | null>) => void
) => {
  if (!value) return;

  setDateType(value);

  const newStartDate = new Date();
  let newEndDate = new Date();

  if (value === "Day") {
    newEndDate = newStartDate;
  } else if (value === "Week") {
    const dayOfWeek = newStartDate.getDay();
    newStartDate.setDate(newStartDate.getDate() - dayOfWeek);
    newEndDate.setDate(newStartDate.getDate() + 6);
  } else if (value === "Month") {
    newStartDate.setDate(1);
    newEndDate = new Date(
      newStartDate.getFullYear(),
      newStartDate.getMonth() + 1,
      0
    );
  }

  setStartDate(newStartDate);
  setEndDate(newEndDate);
};
