import moment from "moment";
import React from "react";

export const FEEDBACK_COLUMNS: TFeedbackTableColumns[] = [
  { key: "feedback", header: "Feedback" },
  {
    key: "employ",
    header: "Employ",
    renderCell: (row: TFeedbackData) => {
      const { employ } = row;
      return `${employ.label}`;
    },
  },
  {
    key: "createdAt",
    header: "Create date",
    renderCell: (params) => {
      const date = new Date(params.createdAt),
        formattedDate = moment(date).format("DD-MM-YYYY");
      return <div style={{ width: 80 }}>{formattedDate}</div>;
    },
  },
];
