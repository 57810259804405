import { ATTENDANCE_COLUMNS } from "./attendance.column";
import { FEEDBACK_COLUMNS } from "./feedback.column";
import { PROJECTS_COLUMNS } from "./project.column";
import { STUDENTS_COLUMNS } from "./students.columns";
import { TASK_REPORT_COLUMNS } from "./taskReport.column";
import { EMPMAPPING_COLUMNS } from "./empMapping.column";

export const COLUMNS = {
  student: STUDENTS_COLUMNS,
  attendace: ATTENDANCE_COLUMNS,
  projects: PROJECTS_COLUMNS,
  feedback: FEEDBACK_COLUMNS,
  taskReport: TASK_REPORT_COLUMNS,
  empMapping: EMPMAPPING_COLUMNS,
};
