import { useMutation } from "react-query";
import { apiUrls } from "../../api-urls";
import { request } from "../../../services/axios.service";
import { TEmpMappingValues } from "../../../form/initial-value/addEmpMapping.values";

const updateEmpMapping = async (data: TEmpMappingValues) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.empMapping.UPDATE_EMPMAPPING,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateEmpMappingMutation = () => {
  return useMutation(updateEmpMapping);
};
