export const groupOverlappingEvents = (
  events: IGroupOverlappingScheduler[],
  checkOverlap: (
    eventA: {
      start_date: string;
      end_date: string;
    },
    eventB: {
      start_date: string;
      end_date: string;
    }
  ) => boolean
) => {
  const groupedEvents = [];
  const assigned = new Array(events.length).fill(false);

  for (let i = 0; i < events.length; i++) {
    if (assigned[i]) continue;
    const group = [events[i]];
    assigned[i] = true;

    for (let j = i + 1; j < events.length; j++) {
      if (checkOverlap(events[i], events[j])) {
        group.push(events[j]);
        assigned[j] = true;
      }
    }
    groupedEvents.push(group);
  }

  return groupedEvents;
};
