import {
  Box,
  Text,
  Grid,
  Paper,
  Badge,
  Divider,
  SimpleGrid,
  Accordion,
} from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import React, { FC } from "react";

interface projectInterface {
  data: TApiDocs[] | null;
}

const ProjectApiInterface: FC<projectInterface> = ({ data }) => {
  // Check if data exists and has content
  if (!data || data.length === 0) {
    return (
      <Box p="xl" style={{ maxWidth: 1200, margin: "auto" }}>
        <Text align="center" size="xl" weight={700} mb="md">
          API Documentation
        </Text>
        <Text align="center" color="dimmed">
          No API data available.
        </Text>
      </Box>
    );
  }

  // Function to determine badge color based on HTTP method
  const getBadgeColor = (method: string) => {
    if (method === "GET") return "blue";
    if (method === "POST") return "green";
    if (method === "PUT") return "orange";
    if (method === "DELETE") return "red";
    return "gray";
  };

  return (
    <Box style={{ maxWidth: 1200, margin: "auto" }}>
      <SimpleGrid cols={1} spacing="md">
        {data.map((api, index) => (
          <Paper p="md" shadow="sm" withBorder key={index}>
            <Box mb="sm">
              <Text size="lg" weight={600}>
                <Badge color={getBadgeColor(api.method)} size="sm" ml={3}>
                  {api.method}
                </Badge>{" "}
                {api.endpoint}
              </Text>
              <Text size="sm" color="dimmed">
                {api.description}
              </Text>
            </Box>

            <Divider my="sm" />

            <Accordion variant="contained" chevronSize={20}>
              {/* Requests*/}
              {/* Requests */}
              <Accordion.Item value="requests">
                <Accordion.Control icon={<IconArrowDown />}>
                  <Text weight={500}>Requests</Text>
                </Accordion.Control>
                <Accordion.Panel>
                  {api.request ? (
                    <>
                      {/* Headers Section */}
                      {api.request.headers &&
                      Object.keys(api.request.headers).length > 0 ? (
                        <>
                          <Text weight={500} size="sm" mb="xs">
                            Headers:
                          </Text>
                          <Grid>
                            {Object.entries(api.request.headers).map(
                              ([key, value], idx) => (
                                <Grid.Col span={12} key={idx}>
                                  <Text size="sm" color="dimmed">
                                    <strong>{key}:</strong> {value}
                                  </Text>
                                </Grid.Col>
                              )
                            )}
                          </Grid>
                          <Divider my="sm" />
                        </>
                      ) : (
                        <Text size="sm" color="dimmed">
                          No headers
                        </Text>
                      )}

                      {/* Body Section */}
                      {api.request.body &&
                      Object.keys(api.request.body).length > 0 ? (
                        <>
                          <Text weight={500} size="sm" mb="xs">
                            Body:
                          </Text>
                          <Text size="sm" color="dimmed">
                            <pre>
                              {JSON.stringify(api.request.body, null, 2)}
                            </pre>
                          </Text>
                        </>
                      ) : (
                        <Text size="sm" color="dimmed">
                          No body parameters
                        </Text>
                      )}
                    </>
                  ) : (
                    <Text size="sm" color="dimmed">
                      No request data
                    </Text>
                  )}
                </Accordion.Panel>
              </Accordion.Item>

              {/* Response */}
              <Accordion.Item value="response">
                <Accordion.Control icon={<IconArrowDown />}>
                  <Text weight={500}>Response</Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Text size="sm" color="dimmed">
                    <pre>{JSON.stringify(api.response, null, 2)}</pre>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Paper>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ProjectApiInterface;
