import { Box } from "@mantine/core";
import React, { useMemo, useRef, useState } from "react";
import CustomTable from "../../component/table";
import ThemeButton from "../../component/button/ThemeButton";
import { IconPlus } from "@tabler/icons-react";
import { COLUMNS } from "../../columns";
import EmpMappingModal, { IEmpMappingModalRef } from "./modal/EmpMappingModal";
import ActionButton from "./components/ActionButton";
import { useGetAllEmpMapping } from "../../hooks/empMapping/query/getAllEmpMapping.query";
import { CONSTANTS } from "../../constant";
import { TEmpMappingData } from "../../types/data/empMapping";

const EmployMapping = () => {
  const modalRef = useRef<IEmpMappingModalRef>(null);
  const tableColumns = [...COLUMNS.empMapping];
  const [activePage, setActivePage] = useState(1);
  const [pagedData, setPagedData] = useState({ total: 0 });
  // const [projects, setProjects] = useState<any[]>([]); // Empty array for placeholder

  // Simulating the addition of a new column for project actions
  const { refetch, data, isLoading } = useGetAllEmpMapping({
    paging: {
      itemPerPage: CONSTANTS.PAGE_LIMIT,
      page: activePage,
    },
  });

  const EmpMapping: TEmpMappingData[] = useMemo(() => {
    if (!isLoading && data && data.data) {
      if (data.pageData) {
        setPagedData(data.pageData);
      }

      return data.data.map((student) => ({
        ...student,
      }));
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push({
    header: "Actions",
    key: "editProjects",
    renderCell: (row: TEmpMappingData) => (
      <ActionButton
        handleClick={() => {
          modalRef.current?.toggleModal();
          modalRef.current?.updateData(row);
        }}
      />
    ),
  });

  return (
    <Box>
      <EmpMappingModal ref={modalRef} reload={refetch} />
      {/* Table Component */}
      <CustomTable
        isDashboardTable={false}
        loading={false}
        columns={tableColumns}
        data={EmpMapping}
        grid={false}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
        headerProps={{
          rightComponent: (
            <Box
              onClick={() => modalRef.current?.toggleModal()}
              style={{ margin: "10px" }}
            >
              <ThemeButton
                title="Add EmployeeMapping"
                mr={15}
                leftIcon={<IconPlus size={20} />}
              />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default EmployMapping;
