import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { TEmpMappingData } from "../../../types/data/empMapping";

const getAllEmpMapping = async (params: TQueryParams) => {
  const response: TServerResponse<TEmpMappingData[], unknown> = await request({
    url: apiUrls.empMapping.GET_ALL_EMPMAPPING,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      searchFieldString: ["employeeId", "projectIds"],
    },
  });
  return response;
};

export const useGetAllEmpMapping = (params: TQueryParams) => {
  return useQuery(["admin", "all-empMapping", params], () =>
    getAllEmpMapping(params)
  );
};
