import moment from "moment";

export const updateDateRange = (
  direction: "prev" | "next",
  startDate: Date | null,
  dateType: string | null,
  setStartDate: (value: React.SetStateAction<Date | null>) => void,
  setEndDate: (value: React.SetStateAction<Date | null>) => void
) => {
  if (!startDate) return;

  const multiplier = direction === "prev" ? -1 : 1;

  const newStartDate = moment(startDate);
  let newEndDate = moment(startDate);

  if (dateType === "Day") {
    newStartDate.add(multiplier, "days");
    newEndDate.add(multiplier, "days");
  } else if (dateType === "Week") {
    const dayOfWeek = newStartDate.day();
    newStartDate.add(multiplier * 7 - dayOfWeek, "days");
    newEndDate = moment(newStartDate).add(6, "days");
  } else if (dateType === "Month") {
    newStartDate.add(multiplier, "months").startOf("month");
    newEndDate = moment(newStartDate).endOf("month");
  } else if (dateType === "Custom") {
    newStartDate.add(multiplier, "days");
    newEndDate.add(multiplier, "days");
  }

  setStartDate(newStartDate.toDate());
  setEndDate(newEndDate.toDate());
};
