import { addMonths, endOfMonth, startOfMonth } from "date-fns";

export const updateMonth = (
  monthOffset: number,
  setStartDate: (value: React.SetStateAction<Date>) => void,
  setEndDate: (value: React.SetStateAction<Date>) => void
) => {
  setStartDate((prev) => startOfMonth(addMonths(prev, monthOffset)));
  setEndDate((prev) => endOfMonth(addMonths(prev, monthOffset)));
};
