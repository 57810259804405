import { Group } from "@mantine/core";
import React, { FC, memo } from "react";
import { useGetAllEmployeeAttendance } from "../../../../hooks/all-student-attendance/query/useAllEmployeeAttendance.query";
import ExcelExport from "../../../../component/excel-export/ExportButton";
import moment from "moment"; // Import moment.js

interface IAttendanceExport {
  date: Date;
  studentId: string;
  Count:
    | {
        workingDays: number;
        leaveDays: number;
        holidays: number;
        absents: number;
      }
    | undefined;
}

const AttendanceExport: FC<IAttendanceExport> = ({
  date,
  studentId,
  Count,
}) => {
  const { data, isLoading } = useGetAllEmployeeAttendance({ date, studentId });

  const excludedFields = [
    "createdAt",
    "updatedAt",
    "studentID",
    "_id",
    "__v",
    "shouldPresent",
  ];

  // // Variables to store counts
  // let workingDays = 0;
  // let leaveDays = 0;
  // let holidayDays = 0;
  // let absentDays = 0; // Added for "Absent" count

  const filteredData =
    data?.data?.map((item: Record<string, any>) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (!excludedFields.includes(key)) {
          // Format inTime and outTime, check if valid
          if (key === "inTime" || key === "outTime") {
            const formattedDate = moment(item[key]).isValid()
              ? moment(item[key]).format("DD-MMM-YYYY HH:mm")
              : "NaN"; // If invalid, set to NaN
            newItem[key] = formattedDate;
          } else {
            newItem[key] = item[key];
          }
        }
      });

      // Debugging: Log the item to check the status field

      // Count working days, leave days, holiday days, and absent days
      // if (item.status === "Working") {
      //   workingDays++;
      // } else if (item.status === "Leave") {
      //   leaveDays++;
      // } else if (item.status === "Holiday") {
      //   holidayDays++;
      // } else if (item.status === "Absent") {
      //   // Handling absent status
      //   absentDays++;
      // }

      return newItem;
    }) || [];

  //     // Use extraData if available, otherwise fallback to calculated data
  const finalWorkingDays = Count?.workingDays;
  const finalLeaveDays = Count?.leaveDays;
  const finalHolidayDays = Count?.holidays;
  const finalAbsentDays = Count?.absents;

  const summaryData = [
    {
      name: "Total Working Days",
      count: finalWorkingDays,
    },
    {
      name: "Total Leave Days",
      count: finalLeaveDays,
    },
    {
      name: "Total Holiday Days",
      count: finalHolidayDays,
    },
    {
      name: "Total Absent Days",
      count: finalAbsentDays,
    },
  ];

  // Combine the filtered data with summary data
  const finalExcelData = [...filteredData, ...summaryData];

  const fileName =
    !isLoading && data && data.data
      ? `Attendance_${
          studentId !== "All" ? (data?.data[0]?.name ?? "") + "_" : ""
        }${new Date().toDateString()}`
      : "";

  return (
    <Group>
      <ExcelExport
        loading={isLoading}
        fileName={fileName}
        ExcelData={finalExcelData as TAttendanceData[]}
      />
    </Group>
  );
};

export default memo(AttendanceExport);
