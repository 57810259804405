import moment from "moment";
import React from "react";

interface IColumn {
  key: string;
  header: string;
  renderCell?: (row: ITaskReport) => React.ReactNode;
  minWidth?: number;
}

export const TASK_REPORT_COLUMNS: IColumn[] = [
  {
    key: "project_name",
    header: "Project Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.project_name ?? "NA"}`;
    },
  },
  {
    key: "content.project_id",
    header: "Project ID",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.project_id ?? "NA"}`;
    },
  },
  {
    key: "content.task_name",
    header: "Task Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return (
        <div>
          <div style={{ fontSize: 12, color: "#000", fontWeight: "bold" }}>
            Id: {content.task_id}
          </div>
          <div>{content?.task_name ?? "NA"}</div>
        </div>
      );
    },
    minWidth: 180,
  },
  {
    key: "content.status_update_by",
    header: "User Name",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.status_update_by ?? "NA"}`;
    },
  },
  {
    key: "content.user_email",
    header: "User Email",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.user_email ?? "NA"}`;
    },
  },
  {
    key: "spend_time",
    header: "Spend Time",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      const formattedSpendTime = Number(content?.spend_time ?? 0).toFixed(2);
      return `${formattedSpendTime} h`;
    },
  },
  {
    key: "createdAt",
    header: "Create date",
    renderCell: (row: ITaskReport) => {
      const { createdAt } = row;
      const date = new Date(createdAt),
        formattedDate = moment(date).format("DD-MM-YYYY");
      return <div>{formattedDate}</div>;
    },
    minWidth: 140,
  },
  {
    key: "status_name",
    header: "Status",
    renderCell: (row: ITaskReport) => {
      const { content } = row;
      return `${content?.status_name ?? "NA"}`;
    },
  },
];
