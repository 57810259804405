import { format } from "date-fns";

export const getEmptySlotText = (
  date: Date,
  isLeaveDay: boolean,
  isHoliday: (date: string) => boolean
): string => {
  const formattedDate = format(date, "yyyy-MM-dd");

  if (isHoliday(formattedDate)) {
    return "Holiday";
  }
  if (isLeaveDay) {
    return "Leave";
  }
  return "";
};
